import { Component, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  viewCount$: Observable<number>;
  onlineCount$: Observable<number>;

  constructor(private state: StateService) {}

  ngOnInit() {
    this.viewCount$ = this.state.viewCount$;
    this.onlineCount$ = this.state.onlineCount$;
  }
}
