import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RankingsRoutingModule } from './rankings-routing.module';
import { DownloadRankingsComponent } from './download-rankings/download-rankings.component';
import { SearchRankingsComponent } from './search-rankings/search-rankings.component';
import { SharedModule } from '../shared/shared.module';
import { NgbTabsetModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DownloadRankingsComponent, SearchRankingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    RankingsRoutingModule,
    NgbButtonsModule,
    SharedModule,
    NgbTabsetModule
  ],
  exports: [DownloadRankingsComponent, SearchRankingsComponent]
})
export class RankingsModule {}
