function formatDate(date: Date): string {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export const buildQueryParams = (opt): string =>
  Object.keys(opt)
    .filter(key => {
      if (Array.isArray(opt[key] && opt[key].length === 0)) {
        return false;
      }
      return opt[key] != null;
    })
    .reduce((params, key) => {
      // return [params, `${key}=${opt[key]}`].join('&');
      if (opt[key].getMonth && typeof opt[key].getMonth === 'function') {
        const dateString = formatDate(opt[key]);
        return params + `${key}=${dateString}` + '&';
      }
      if (Array.isArray(opt[key])) {
        return (
          params +
          (<Array<any>>opt[key]).map(k => `${key}=${k}`).join('&') +
          '&'
        );
      }

      return params + `${key}=${opt[key]}` + '&';
    }, '?');
