import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, switchMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  acceptedTos$ = new BehaviorSubject<boolean>(false);
  // acceptedTos$ = new BehaviorSubject<boolean>(true);

  viewCount$ = new BehaviorSubject<number>(0);
  onlineCount$ = new BehaviorSubject<number>(0);
  private readonly endpoint: string = '/api/v1/count/';
  constructor(private http: HttpClient) {}

  acceptTos(): void {
    this.incrementViewCount()
      .pipe(
        tap(() => this.acceptedTos$.next(true)),
        switchMapTo(this.loadOnlineCount())
      )
      .subscribe();
  }

  incrementViewCount(): Observable<number> {
    return this.http
      .post<number>(this.endpoint + 'pageViews', null)
      .pipe(tap(count => this.viewCount$.next(count)));
  }

  loadOnlineCount(): Observable<number> {
    return this.http
      .get<number>(this.endpoint + 'onlineCount')
      .pipe(tap(count => this.onlineCount$.next(count)));
  }
}
