import { Pipe, PipeTransform } from '@angular/core';

export function formatToDogDate(value: string | number | Date): string {
  if (value === '' || value == null) {
    return '';
  }
  const date: Date = new Date(value);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year - 1911 + '年' + month + '月' + day + '日';
  // return year - 1911 + '/' + month + '/' + day;
}

@Pipe({
  name: 'toTwDate'
})
export class ToTwDatePipe implements PipeTransform {
  transform(value: any): any {
    try {
      return formatToDogDate(value);
    } catch (error) {
      throw error;
    }
  }
}
