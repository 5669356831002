import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { buildQueryParams } from '../helpers';
import { List } from '../generics';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SearchPartyYearQuery {
  year?: number;
  page?: number;
  pageSize?: number;
}

export class SearchPartyQuery {
  year?: number;
  pptCd?: number;
  acctTp?: string;
  page?: number;
  pageSize?: number;
}

export class SearchElectionAndIndividualQuery {
  electionName?: string;
  electionYear?: number;
  electionDistrictName?: string;
  keyword?: string;
  serial?: string;
  page?: number;
  pageSize?: number;
}

export enum DetailSearchType {
  Candidate = 'Candidate',
  PoliticalParty = 'PoliticalParty',
  Donor = 'Donor',
  ExpenditureTarget = 'ExpenditureTarget'
}
export enum DetailOrder {
  Date = 'Date',
  Amount = 'Amount'
}

export enum DetailOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum DetailAmountType {
  Income = 'Income',
  Expenditure = 'Expenditure'
}

export class SearchAdvancedQuery {
  keyword?: string;
  keywordRanges?: DetailSearchType[];

  // types?: number[];
  // for candidate only start
  electionYear?: string | number;
  electionName?: string;
  electionDistrict?: string;

  electionCode?: string;

  accountingSerial?: number | string;
  // for candidate only end
  // for org only start
  reportedYear?: number | string;

  // for org only end

  target?: string;
  isMoney?: boolean;
  classType?: number;
  transactionDateBegin?: string;
  transactionDateEnd?: string;
  amountOver3Million?: boolean;
  amountBegin?: number;
  amountEnd?: number;

  amountPrefix?: DetailAmountType;
  orderBy?: DetailOrder;
  orderDirection?: DetailOrderDirection;
  thenOrderBy?: DetailOrder;
  thenOrderDirection?: DetailOrderDirection;

  page?: number;
  pageSize?: number;

  // TODO:
  donor?: string;
  //payType?: "1" | "2" | "3";
  payType: string;
  exposeRemark?: "Y";
  //returnOrPaytrs?: "Y"; 20201216 added
  returnOrPaytrs?: string;
  diffVersionStr?: "Y";
  saveAccountDateBegin?: string;
  saveAccountDateEnd?: string;
  from: "Account" | "Election" | "PoliticalParty" | null;
}

export class ElectionResource {
  code: string;
  name: string;
  year: string;
}

export enum BatchType {
  Group = 'Group',
  Individual = 'Individual'
}

export class AccountReportResource {
  id: number;
  name: string;
}

export enum AccountType {
  Party = 'Party',
  Group = 'Group',
  Individual = 'Individual',
  President = 'President'
}

export class ClassType {
  type: BatchType;
  ctrlTp: '0' | '1' | '2' | '3';
  ctrlNm: string;
  acctCd: string;
  acctNm: string;
}

export class Party {
  pptCd: number;
  pptNm: string;
  acctTp: string;
}

export class SearchFormData {
  electionYears: number[];
  reportYears: number[];
  elections: ElectionResource[];
  accountReports: AccountReportResource[];
  classTypes: ClassType[];
  electionDistricts: string[];
  parties: Party[];
}

export enum SearchType {
  Account = 'Account',
  Election = 'Election',
  PoliticalParty = 'PoliticalParty',
  PoliticalPartyLarge = 'PoliticalPartyLarge'
}

export enum DownloadType {
  PDF = 'PDF',
  CSV = 'CSV',
  ZIP = 'ZIP'
}

export class DownloadRow {
  accountNumber: string;
  accountType: AccountType;
  yearOrSerial?: number;
  version?: number;
  electionName?: string;
  electionArea?: string;
  electionYear?: string;
  electionName2?: string;
  name?: string;
  path?: string;
  politicalPartyCode?: number;
  type?: SearchType;
  pdfFileName: string;
  pdfFileDescription: string;
  csvFileName: string;
  csvFileDescription: string;
  zipFileName: string;
  zipFileDescription: string;
  downloadPdf: string;
  downloadCsv: string;
  downloadZip: string;
}

export class AdvancedRow {
  id: number;
  transactionDate: Date | string;
  name: string;
  electionName: string;
  yearOrSerial: number;
  typeCode: string;
  donor: string;
  donorIdentifier: string;
  receivedAmount: number;
  donationAmount: number;
  donationUse: string;
  isMoney: boolean;
  donorAddress: string;
  tel: string;
  rewriteDate: Date | string;
  payType: string;
  saveAccountDate?: Date | string;
  returnOrPaytrs: string;
  exposeRemark?: '是' | 'Y' | '否' | 'N' | string;
  rpIntraName?: string;
  rpIntraTitle?: string;
  rpPartyName?: string;
  rpPartyTitle?: string;
  rpRelationStr?: string;
  diffVersionStr?: string;
  updateDatetimeB?: Date | string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private readonly endpoint: string = '/api/v1/search';
  constructor(private http: HttpClient) {}

  searchAdvanced(opt: SearchAdvancedQuery): Observable<List<AdvancedRow>> {
    return this.http.get<List<AdvancedRow>>(
      this.endpoint + buildQueryParams(opt)
    );
  }

  exportAdvanced(opt: SearchAdvancedQuery): Observable<any> {
    return this.http.get(this.endpoint + '/export/' + buildQueryParams(opt), {
      observe: 'response',
      responseType: 'blob'
    });
  }

  searchParties(opt: SearchPartyQuery): Observable<List<DownloadRow>> {
    return this.http.get<List<DownloadRow>>(
      this.endpoint + '/parties' + buildQueryParams(opt)
    );
  }

  searchPartiesByYear(
    opt?: SearchPartyYearQuery
  ): Observable<List<DownloadRow>> {
    return this.http.get<List<DownloadRow>>(
      this.endpoint + '/parties/years' + buildQueryParams(opt)
    );
  }

  searchElections(
    opt: SearchElectionAndIndividualQuery
  ): Observable<List<DownloadRow>> {
    return this.http.get<List<DownloadRow>>(
      this.endpoint + '/elections' + buildQueryParams(opt)
    );
  }

  searchIndividuals(
    opt: SearchElectionAndIndividualQuery
  ): Observable<List<DownloadRow>> {
    return this.http.get<List<DownloadRow>>(
      this.endpoint + '/individuals' + buildQueryParams(opt)
    );
  }

  getData(): Observable<SearchFormData> {
    return this.http.get<SearchFormData>(this.endpoint + '/data');
  }
}
