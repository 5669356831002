import { Pipe, PipeTransform } from '@angular/core';

export function serialToZh(value: string | number): string {
  if (value == null) {
    return '';
  }
  switch (+value) {
    case 1:
      return '首次';
    case 2:
      return '第一次賸餘';
    case 3:
      return '第二次賸餘';
    case 4:
      return '第三次賸餘';
    case 5:
      return '第四次賸餘';
    case 6:
      return '第五次賸餘';
    default:
      return value + '';
  }
}

@Pipe({
  name: 'toZh'
})
export class ToZhPipe implements PipeTransform {
  transform(value: any, subset?: string): string {
    if (subset && subset === 'logKind') {
      switch (value) {
        case 'QueryAdvanced':
          return '一般查詢';
        case 'QueryAccount':
          return '專戶查詢';
        case 'QueryParties':
          return '政黨查詢';
        case 'QueryElections':
          return '選舉查詢';
        case 'DownloadAdvanced':
          return '一般下載';
        case 'DownloadAccount':
          return '專戶下載';
        case 'DownloadParties':
          return '政黨下載';
        case 'DownloadElections':
          return '選舉下載';
      }
    }

    if (subset && subset === 'isMoney') {
      return value ? '金錢' : '非金錢';
    }
    if (subset && subset === 'tf') {
      return value ? '是' : '否';
    }

    if (subset && subset === 'serial') {
      return serialToZh(value);
    }

    if (subset && subset === 'typeCode') {
      switch (+value) {
        case 11:
          return '個人捐贈收入';
        case 12:
          return '營利事業捐贈收入';
        case 13:
          return '政黨捐贈收入';
        case 14:
          return '人民團體捐贈收入';
        case 15:
          return '匿名捐贈';
        case 16:
          return '其他收入';
        case 20:
          return '人事費用支出';
        case 21:
          return '宣傳支出';
        case 22:
          return '租用宣傳車輛支出';
        case 23:
          return '租用競選辦事處支出';
        case 24:
          return '集會支出';
        case 25:
          return '交通旅運支出';
        case 26:
          return '雜支支出';
        case 27:
          return '繳庫支出';
        case 28:
          return '返還捐贈支出';
        case 29:
          return '公共關係費用支出';
        case 31:
          return '個人捐贈收入';
        case 32:
          return '營利事業捐贈收入';
        case 33:
          return '人民團體捐贈收入';
        case 34:
          return '匿名捐贈收入';
        case 35:
          return '其它收入';
        case 41:
          return '人事費用支出';
        case 42:
          return '業務費用支出';
        case 43:
          return '公共關係費用支出';
        case 44:
          return '選務費用支出';
        case 45:
          return '捐贈黨員之競選支出';
        case 46:
          return '雜支支出';
        case 47:
          return '繳庫支出';
        case 48:
          return '捐贈其推薦之公職候選人競選費用支出';
        case 49:
          return '返還捐贈支出';
        case 51:
          return '其他收入';
        case 52:
          return '前期賸餘';
        case 61:
          return '當選後與其公務有關之費用支出';
        case 62:
          return '捐贈政治團體或所屬政黨支出';
        case 63:
          return '捐贈教育、文化、公益、慈善機構或團體支出';
        case 64:
          return '參加公職人員選舉使用支出';
        case 65:
          return '支出合計';
        case 66:
          return '專戶結存金額';
        case 67:
          return '變賣金錢以外賸餘財產';
        case 68:
          return '返還捐贈支出';
        case 69:
          return '繳庫支出';
        default:
          return '';
      }
    }
    if (subset == null) {
      switch (value) {
        case 'canManageNews':
          return '最新消息及資料公開訊息';
        case 'canManageBatches':
          return '公開查閱資料設定';
        case 'canPresentBatches':
          return '公開查閱資料呈核';
        case 'canApproveBatches':
          return '公開查閱資料審核';
        case 'canEmergencyRecallBatches':
          return '公開查閱資料緊急下架 ';
        case 'canViewStatisticsClicks':
          return '查詢/下載統計表 ';
        case 'canViewStatisticsDetailClicks':
          return '查詢/下載明細表 ';
        case 'canViewStatisticsBatchItems':
          return '專戶類別統計表 ';
        case 'canViewStatisticsTop':
          return '查詢排行榜 ';
        case 'canViewStatisticsDownload':
          return '下載排行榜 ';
        case 'canViewStatisticsReceivedAndDonations':
          return '收入/支出/餘絀排行榜 ';
        case 'canManageAccounts':
          return '使用者';
        case 'canManageGroups':
          return '群組';
        case 'canManageSystem':
          return '設定管理';
        case 'LatestNews':
          return '最新消息';
        case 'Announcement':
          return '資訊開放訊息';
        case 'Group':
          return '政黨、政治團體';
        case 'Individual':
          return '擬參選人';
        default:
          return '';
      }
    }
  }
}
