export class Paging {
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  recordCount: number;
}

export interface ApiError {
  code: number;
  error: string;
  description?: string;
}

export interface List<T> {
  data: T[];
  paging: Paging;
}

export interface UnpagedList<T> {
  data: T[];
}

export interface Wrapped<T> {
  data: T[];
}

export interface Breadcrumb {
  link?: string[];
  queryParams?: any | null;
  title: string;
}
