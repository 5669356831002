import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DownloadRankingsComponent } from './download-rankings/download-rankings.component';
import { SearchRankingsComponent } from './search-rankings/search-rankings.component';
import { AcceptedTosGuard } from '../core/guards/accepted-tos.guard';

const routes: Routes = [
  {
    path: 'search',
    component: SearchRankingsComponent,
    canActivate: [AcceptedTosGuard],
    data: {
      type: 'search'
    }
  },
  {
    path: 'downloads',
    component: SearchRankingsComponent,
    canActivate: [AcceptedTosGuard],
    data: {
      type: 'downloads'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RankingsRoutingModule {}
