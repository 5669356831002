import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StateService } from '../state.service';

@Injectable({
  providedIn: 'root'
})
export class AcceptedTosGuard implements CanActivate, CanActivateChild {
  constructor(private state: StateService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.state.acceptedTos$.pipe(
      tap(accepted => {
        if (!accepted) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.state.acceptedTos$.pipe(
      tap(accepted => {
        if (!accepted) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
}
