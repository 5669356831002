import { Component, OnInit, Input } from '@angular/core';
import {
  RankingsService,
  Ranking,
  RankingDateRange,
  RankingRequest
} from '../rankings.service';
import { Subject, Observable } from 'rxjs';
import { List, Paging } from 'src/app/generics';
import { startWith, switchMap, tap, withLatestFrom, map } from 'rxjs/operators';

@Component({
  selector: 'app-download-rankings',
  templateUrl: './download-rankings.component.html',
  styleUrls: ['./download-rankings.component.scss']
})
export class DownloadRankingsComponent implements OnInit {
  @Input()
  wrapped = true;

  selectedDateRange: RankingDateRange = RankingDateRange.Yesterday;

  initialReqState: RankingRequest = {
    dateRange: RankingDateRange.Yesterday,
    page: 1,
    pageSize: 10
  };

  reqState: RankingRequest = {
    dateRange: RankingDateRange.Yesterday,
    page: 1,
    pageSize: 10
  };
  refresher$ = new Subject<RankingRequest>();

  advs$: Observable<List<Ranking>>;
  advsPage$ = new Subject<number>();
  advsPaging: Paging | null = null;

  groups$: Observable<List<Ranking>>;
  groupsPage$ = new Subject<number>();
  groupsPaging: Paging | null = null;

  individuals$: Observable<List<Ranking>>;
  individualsPage$ = new Subject<number>();
  individualsPaging: Paging | null = null;

  elections$: Observable<List<Ranking>>;
  electionsPage$ = new Subject<number>();
  electionsPaging: Paging | null = null;

  constructor(private rankingService: RankingsService) {}

  ngOnInit() {
    this.advs$ = this.refresher$.pipe(
      startWith(this.initialReqState),
      withLatestFrom(this.advsPage$.pipe(startWith(1))),
      map(([query, page]: [RankingRequest, number]) => ({ ...query, page })),
      switchMap(query =>
        this.rankingService
          .advDownloads(query)
          .pipe(tap(res => (this.advsPaging = res.paging)))
      )
    );
    this.groups$ = this.refresher$.pipe(
      startWith(this.initialReqState),
      withLatestFrom(this.groupsPage$.pipe(startWith(1))),
      map(([query, page]: [RankingRequest, number]) => ({ ...query, page })),
      switchMap(query =>
        this.rankingService
          .groupDownloads(query)
          .pipe(tap(res => (this.groupsPaging = res.paging)))
      )
    );
    this.individuals$ = this.refresher$.pipe(
      startWith(this.initialReqState),
      withLatestFrom(this.individualsPage$.pipe(startWith(1))),
      map(([query, page]: [RankingRequest, number]) => ({ ...query, page })),
      switchMap(query =>
        this.rankingService
          .individualDownloads(query)
          .pipe(tap(res => (this.individualsPaging = res.paging)))
      )
    );
    this.elections$ = this.refresher$.pipe(
      startWith(this.initialReqState),
      withLatestFrom(this.electionsPage$.pipe(startWith(1))),
      map(([query, page]: [RankingRequest, number]) => ({ ...query, page })),
      switchMap(query =>
        this.rankingService
          .electionDownloads(query)
          .pipe(tap(res => (this.electionsPaging = res.paging)))
      )
    );
  }

  onElectionPaginate(page: number): void {
    this.electionsPage$.next(page);
  }
  onIndividualPaginate(page: number): void {
    this.individualsPage$.next(page);
  }
  onGroupPaginate(page: number): void {
    this.groupsPage$.next(page);
  }

  onUpdateDateRange(): void {
    this.refresher$.next(this.reqState);
  }
}
