import {
  Component,
  EventEmitter,
  Output,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { Paging } from 'src/app/generics';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input()
  paging: Paging;

  @Input()
  showCount: boolean = true;

  @Output()
  pageChange = new EventEmitter<number>();

  onPageChange(page: number): void {
    if (this.paging.pageNumber !== page) {
      this.pageChange.emit(page);
    }
  }

  get min() {
    return this.paging.pageNumber > 0;
  }

  get max() {
    return this.paging.pageCount;
  }

  get range() {
    const delta = 4;
    const left = this.paging.pageNumber - delta;
    const right = this.paging.pageNumber + delta + 1;
    const result = Array.from(
      { length: this.paging.pageCount },
      (v, k) => k + 1
    ).filter(i => i && i >= left && i < right);

    return result;
  }
}
