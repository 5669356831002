import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { List } from '../generics';
import { Observable } from 'rxjs';
import { buildQueryParams } from '../helpers';

export enum NewsItemType {
  LatestNews = 'LatestNews',
  Announcement = 'Announcement'
}

export class NewsReadOption {
  isStickied?: boolean;
  type?: NewsItemType;
  page: number;
  pageSize: number;
}

export class NewsItem {
  id: number;
  type: NewsItemType;
  isLink: boolean;
  linkUrl: string;
  title: string;
  content: string;
  date: string | Date;
  attachments: NewsItemAttachments[];
}

export class NewsItemAttachments {
  id: number;
  fileName: string;
  description: string;
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly endpoint: string = '/api/v1/news/';
  constructor(private http: HttpClient) {}
  read(opt: NewsReadOption): Observable<List<NewsItem>> {
    return this.http.get<List<NewsItem>>(this.endpoint + buildQueryParams(opt));
  }

  readOne(id: number): Observable<NewsItem> {
    return this.http.get<NewsItem>(this.endpoint + id);
  }
}
