import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildQueryParams } from '../helpers';
import { List } from '../generics';
import { AccountType } from '../search/search.service';

export enum RankingDateRange {
  Yesterday = 'Yesterday',
  ThisWeek = 'ThisWeek',
  ThisMonth = 'ThisMonth',
  ThisYear = 'ThisYear',
  LastYear = 'LastYear'
}

export enum QueryType {
  AdvancedSearch = 'AdvancedSearch',
  Election = 'Election',
  Group = 'Group',
  Individual = 'Individual'
}
export enum RankingType {
  Search = 'Search',
  Download = 'Download'
}

export class Ranking {
  queryType: QueryType;
  rankingType: RankingType;
  name: string;
  count: number;
  electionYear?: number;
  electionName?: string;
  accountingSerial?: number;
  electionDistrictName?: string;
  partyYear?: number;
  pptCd?: number;
  accountType?: AccountType;
}

export class RankingRequest {
  dateRange?: RankingDateRange;
  page: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class RankingsService {
  private readonly endpoint: string = '/api/v1/rankings/';

  constructor(private http: HttpClient) {}

  groupDownloads(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'downloads/groups/' + buildQueryParams(req)
    );
  }

  groupData(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'searches/groups/' + buildQueryParams(req)
    );
  }

  individualData(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'searches/individuals/' + buildQueryParams(req)
    );
  }
  individualDownloads(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'downloads/individuals/' + buildQueryParams(req)
    );
  }
  electionData(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'searches/elections/' + buildQueryParams(req)
    );
  }
  electionDownloads(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'downloads/elections/' + buildQueryParams(req)
    );
  }

  advSearches(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'searches/advanced/' + buildQueryParams(req)
    );
  }
  advDownloads(req?: RankingRequest): Observable<List<Ranking>> {
    return this.http.get<List<Ranking>>(
      this.endpoint + 'downloads/advanced/' + buildQueryParams(req)
    );
  }
}
