import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Breadcrumb } from 'src/app/generics';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  @Input()
  breadcrumbs: Breadcrumb[] | null = null;
}
