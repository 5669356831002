import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ToTwDatePipe } from './pipes/to-tw-date.pipe';
import { ToZhPipe } from './pipes/to-zh.pipe';

const COMPONENTS = [
  PaginationComponent,
  BreadcrumbsComponent,
  ToTwDatePipe,
  ToZhPipe
];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, RouterModule],
  exports: COMPONENTS
})
export class SharedModule {}
