import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { RankingsModule } from '../rankings/rankings.module';
import { FormsModule } from '@angular/forms';
const COMPONENTS = [
  NavComponent,
  FooterComponent,
  NotFoundComponent,
  HomeComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    SharedModule,
    RankingsModule
  ],
  exports: COMPONENTS
})
export class CoreModule {}
