import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '../state.service';
import { first } from 'rxjs/operators';
import { NewsService, NewsItemType } from 'src/app/news/news.service';

export function makeid() {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 4; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('tos')
  tosModal: TemplateRef<any>;

  verificationCode = '';
  verificationConfirm = '';

  latestNews$ = this.newsService.read({
    page: 1,
    pageSize: 10,
    type: NewsItemType.LatestNews
  });
  announcements$ = this.newsService.read({
    page: 1,
    pageSize: 10,
    type: NewsItemType.Announcement
  });

  constructor(
    private modalService: NgbModal,
    private state: StateService,
    private newsService: NewsService
  ) {
    this.verificationCode = makeid();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.state.acceptedTos$.pipe(first()).subscribe(accepted => {
      if (!accepted) {
        setTimeout(() => {
          const modalRef = this.modalService.open(this.tosModal, {
            backdrop: 'static',
            centered: true,
            size: 'lg'
          });
          modalRef.result.then(
            () => {
              this.state.acceptTos();
            },
            () => {}
          );
        });
      }
    });
  }
}
