import { Component, OnInit, Input } from '@angular/core';
import {
  RankingDateRange,
  RankingRequest,
  Ranking,
  RankingsService,
  QueryType
} from '../rankings.service';
import { Subject, Observable, combineLatest } from 'rxjs';
import { List, Paging } from 'src/app/generics';
import { switchMap, map, tap, startWith, filter } from 'rxjs/operators';
import { AccountType } from 'src/app/search/search.service';
import { serialToZh } from 'src/app/shared/pipes/to-zh.pipe';
import { ActivatedRoute } from '@angular/router';
// import { switchMap, tap } from 'rxjs/operators';

function getAccountSerialByValue(val: string | number): string {
  switch (+val) {
    case 1:
      return '首次';
    case 2:
      return '第一次賸餘';
    case 3:
      return '第二次賸餘';
    case 4:
      return '第三次賸餘';
    case 5:
      return '第四次賸餘';
    case 6:
      return '第五次賸餘';
    default:
      return val + '';
  }
}

@Component({
  selector: 'app-search-rankings',
  templateUrl: './search-rankings.component.html',
  styleUrls: ['./search-rankings.component.scss']
})
export class SearchRankingsComponent implements OnInit {
  @Input()
  wrapped = true;

  @Input()
  type: 'downloads' | 'search' = 'search';

  initialReqState: RankingRequest = {
    dateRange: RankingDateRange.Yesterday,
    page: 1,
    pageSize: 10
  };

  reqState: RankingRequest = {
    dateRange: RankingDateRange.Yesterday,
    page: 1,
    pageSize: 10
  };
  refresher$ = new Subject<RankingRequest>();

  section$ = new Subject<QueryType>();

  section: QueryType = QueryType.AdvancedSearch;

  rankings$: Observable<List<Ranking>>;
  page$ = new Subject<number>();

  constructor(
    private rankingService: RankingsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data
      .pipe(
        filter(data => data && data.type),
        map(data => data.type)
      )
      .subscribe(type => (this.type = type));

    this.rankings$ = combineLatest(
      this.refresher$.pipe(startWith(this.reqState)),
      this.section$.pipe(startWith(this.section)),
      this.page$.pipe(startWith(1))
    ).pipe(
      switchMap(
        ([query, section, page]: [RankingRequest, QueryType, number]) => {
          query.page = page;
          switch (section) {
            case QueryType.Election:
              return this.type === 'search'
                ? this.rankingService.electionData(query)
                : this.rankingService.electionDownloads(query);
            case QueryType.Group:
              return this.type === 'search'
                ? this.rankingService.groupData(query)
                : this.rankingService.groupDownloads(query);
            case QueryType.Individual:
              return this.type === 'search'
                ? this.rankingService.individualData(query)
                : this.rankingService.individualDownloads(query);
            case QueryType.AdvancedSearch:
            default:
              return this.type === 'search'
                ? this.rankingService.advSearches(query)
                : this.rankingService.advDownloads(query);
          }
        }
      )
    );
  }
  onUpdateDateRange(): void {
    // this.refresher$.next(this.reqState);
  }
  onRefresh(): void {
    this.refresher$.next(this.reqState);
  }

  onUpdateSection(): void {
    this.section$.next(this.section);
  }

  rowRouterLink(row: Ranking): string[] {
    if (row.queryType === QueryType.AdvancedSearch) {
      return ['/', 'data', this.type, 'advanced'];
    }
    if (row.queryType === QueryType.Group) {
      return ['/', 'data', this.type, 'group'];
    }

    if (row.queryType === QueryType.Election) {
      return ['/', 'data', this.type, 'election'];
    }
    if (row.queryType === QueryType.Individual) {
      return ['/', 'data', this.type, 'individual'];
    }
  }

  rowName(row: Ranking): string {
    switch (row.queryType) {
      case QueryType.Group:
        return (
          (row.name || '全部政黨、政治團體') +
          ',' +
          ((row.partyYear || '') + (row.partyYear != null ? '年' : '全部年度'))
        );

      case QueryType.Election:
        return (
          (row.electionName || '全部選舉') +
          ',' +
          (row.accountingSerial != null && row.accountingSerial !== 0
            ? getAccountSerialByValue(row.accountingSerial) + ','
            : '') +
          '' +
          (row.electionDistrictName || '全部縣市別')
        );
      case QueryType.Individual:
        return (
          (row.name != null ? '「' + row.name + '」' : '') +
          (row.electionName || '全部選舉') +
          (row.accountingSerial != null
            ? serialToZh(row.accountingSerial)
            : '') +
          (row.electionDistrictName || '全部縣市別')
        );
      case QueryType.AdvancedSearch:
      default:
        return row.name;
    }
  }

  rowQueryParams(row: Ranking): any {
    if (row.queryType === QueryType.AdvancedSearch) {
      return { keyword: row.name };
    }

    if (row.queryType === QueryType.Individual) {
      return {
        keyword: row.name || null,
        year: row.electionYear || null,
        electionName: row.electionName || null,
        serial: row.accountingSerial || null,
        district: row.electionDistrictName || null
      };
    }

    if (row.queryType === QueryType.Election) {
      return {
        year: row.electionYear || null,
        name: row.electionName || null,
        district: row.electionDistrictName || null
      };
    }
    if (row.queryType === QueryType.Group) {
      const { name, partyYear, pptCd, accountType } = row;
      let acctTp: string = '';
      switch (accountType) {
        case AccountType.Party:
          acctTp = '1';
          break;
        case AccountType.Group:
          acctTp = '2';
          break;
        case AccountType.Individual:
          acctTp = '3';
          break;
        case AccountType.President:
          acctTp = '4';
          break;
      }
      return { name, year: partyYear, pptCd, acctTp };
    }
    // if (row.rankingType === RankingType.Search) {
    //   if (row.queryType === QueryType.AdvancedSearch)
    // }
    // {prop: 'xxx'}
  }
}
